export const BASE_URL = 'https://mojedrazbe.com/';

// Chronos-1 GraphQL (upload) API
export const API_BASE_URL = 'https://api.mojedrazbe.com/';
export const API_BASE_URL_DEV = 'https://apidev.mojedrazbe.com/';
export const API_APIKEY = 'cae036ff-748f-4c7f-9254-536822383de0';

// Third-party register
export const THIRD_PARTY_AUTH_PASSWORD_SALT = '84B$';

// Third-party settings
export const GOOGLE_MAPS_EMBED_APIKEY = 'AIzaSyAFx5FDCSvUEZ4ZeDL2USCruz7g4h7l3LI'; // <--- gmail najdiflet, projct ljubljana nepremcinine www > Mojedrazbe.com (ima nastavljen billing account)! AIzaSyBTDRHdzkS93rFNS1jvRFQ8-irPKXPt4Xk (gmail najdiflet, project dražbe = nima billinga nastavljenega - ker ga ne more več. Kreiraj nov google account!)
export const GOOGLE_OAUTH_CLIENT_ID = '745258142480-k6i2t4rbesdfl46ebt0vi1nkq653h8bs.apps.googleusercontent.com'; // Najdiflet google account (project: drazbe)
export const GOOGLE_TAG_ID = 'G-6L0GYGHD0N'; // gmail najdiflet, mojikvadrati "group" v google analyics (Mojedrazbe.com)
export const FACEBOOK_OAUTH_APP_ID = '854203422182144'; // najdiflet facebook account (dostop v trello)
