import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import fetch from 'isomorphic-unfetch';
import { API_BASE_URL_DEV, API_BASE_URL, API_APIKEY } from '../config.js';

export default function createApolloClient(initialState, ctx) {

	// The `ctx` (NextPageContext) will only be present on the server.
	// use it to extract auth headers (ctx.req) or similar.

	const httpLink = createHttpLink({
		uri: `${process.env.NODE_ENV === 'development' ? API_BASE_URL_DEV : API_BASE_URL}graphql` // overriden in helpers/gql.js > setContext (why? request name set as GET parameter for easier debug)
	});

	const authLink = setContext((_, { headers }) => {
		const accessToken = (typeof window !== 'undefined' && window.localStorage.getItem('access_token')) ? window.localStorage.getItem('access_token') : null;

		return {
			headers: {
				// same as useRest
				Authorization: accessToken ? `Bearer ${accessToken}` : null,
				Environment: process.env.NODE_ENV,
				Apikey: API_APIKEY,
				Possessid: (typeof window !== 'undefined' && window.localStorage.getItem('possessid')) ? window.localStorage.getItem('possessid') : null,
				...headers // override original headers, if need - or just append new headers
			}
		};
	});

	const errorLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors) {
			graphQLErrors.forEach(({ message, locations, path }) => {
				console.log(`[GraphQL error]: ${message}, Location: ${locations}, Path: ${path}`);
			});

			if (networkError) console.log(`[Network error]: ${networkError}`);
		}
	});

	return new ApolloClient({
		ssrMode: Boolean(ctx),
		link: errorLink.concat(authLink).concat(httpLink),
		cache: new InMemoryCache().restore(initialState),
		fetch
	});
}
